import { FunctionComponent, useEffect, useState } from 'react';
import { useLoad, useTranslate } from '@xFrame4/components/Hooks';
import Layout from '@components/layout/Layout';
import { PageProps } from './Page';

interface HomePageProps extends PageProps
{

}

const HomePage: FunctionComponent<HomePageProps> = (props) =>
{
    const t = useTranslate();

    /** Render */
    return (
        <>
            <Layout>
                <>
                {t('HOME_PAGE')}
                </>
            </Layout>
        </>
    );
}

export default HomePage;