import { FunctionComponent, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useTranslate } from '@xFrame4/components/Hooks';

interface AppProps
{

}

const App: FunctionComponent<AppProps> = () =>
{
    const t = useTranslate();
    
    // set document title
    useEffect(() =>
    {
        document.title = t('APP_TITLE');
    }, []);

    /** Render */
    return (
        <>
            <Outlet />
        </>
    );
}

export default App;