import React from 'react';
import ReactDom from 'react-dom/client';
import { BrowserRouter, Routes, Route, HashRouter } from "react-router-dom";
import config from '@config';
import { store } from '@redux/store';
import { Provider } from 'react-redux';
import App from './App';
import HomePage from '@components/pages/HomePage';

run();

function run()
{
    const rootElement = document.getElementById('root');
    
    if (rootElement != null)
    {
        const root = ReactDom.createRoot(rootElement);
        root.render(
            <Provider store={store}>
                <BrowserRouter basename={config.reactRouterBaseName}>
                    <Routes>
                        <Route path="/" element={<App />}>
                            <Route index element={<HomePage />} />
                        </Route>
                    </Routes>
                </BrowserRouter>
            </Provider>,
        );
    }
}